import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import classnames from "classnames";
import { LocationIcon } from "static/media/location-icon";
import { DeleteButton } from "components/audience-form/components/campaign-location/h2h-precise-locations/components/DeleteButton";
import { getPolygonCenterPoint } from "components/audience-map/utils.js";
import { displayAddressName } from "../../../utils.js";
import { styles } from "./styles.js";
import { useStore } from "../../../../../../../store";

const useStyles = makeStyles(() => styles);

export const AreaCard = ({ area, index, addresses, isSubmitted, hoveredMission, setHoveredMission }) => {
  const classes = useStyles();
  const {
    costsCalculationData: { detailedCost },
    setAddresses,
    updateMap,
    onAddressClick,
  } = useStore();

  const { distribution, printing } = detailedCost;

  const handleLocationCardClick = () => {
    if (!area.editMode) {
      if (area?.position?.lat || area?.paths?.[0][0]) {
        updateMap({
          center: area?.position?.lat
            ? { lat: area.position.lat, lng: area.position.lng }
            : getPolygonCenterPoint(area?.paths?.[0]) || {},
          zoom: 15,
        });
      }
      onAddressClick(area);
      // handleMapItemClick({ item: area, selectedCities, activeSelectedCity: city, setState });
    }
  };

  const handleAreaDelete = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const updatedAddresses = addresses.filter((address) => address.id !== area.id);
    setAddresses(updatedAddresses);
    setHoveredMission("");
  };

  const handleMouseOver = () => {
    setHoveredMission(area.id);
  };

  const handleMouseOut = () => {
    setHoveredMission("");
  };

  const flyerPack = printing.quantity / distribution.quantity;

  return (
    <Box
      className={classnames(classes.locationCardContainer, { [classes.hovered]: hoveredMission === area.id })}
      onClick={handleLocationCardClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.locationTitle}>
          <LocationIcon className={classes.locationIcon} />
          {displayAddressName(addresses)[index]?.value || ""}
        </Typography>
        <Typography className={classes.missionsCount}>
          Flyers: <span className={classes.totalMissions}>{flyerPack}</span>
        </Typography>
        {!isSubmitted && (
          <Box className={classes.locationOptionsBtn}>
            <DeleteButton onClick={handleAreaDelete} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
