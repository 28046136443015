import React from "react";
import { makeStyles } from "@material-ui/core";
import { SettingsIcon } from "components/icons/components/Settings";
import { styles } from "./styles";

const useStyles = makeStyles(() => styles);

export const RouteStatistics = ({ route }) => {
  const classes = useStyles();
  return route ? (
    <div className={classes.statisticsBlockContainer}>
      <div className={classes.statisticsHeaderWrapper}>
        <span>
          {route.ZIP} - {route.CROUTE} {route.ZIP_NAME}
        </span>
      </div>
      <div className={classes.statistics}>Statistics</div>
      {/* <div className={classes.statisticsRow}>
        <span>Avg People per Household</span> <span>N/A</span>
      </div>*/}
      <div className={classes.statisticsRow}>
        <span>Single Family Home</span> <span>{route.HOME_COUNT}</span>
      </div>
      <div className={classes.statisticsRow}>
        <span>Apartments</span> <span>{route.APT_COUNT}</span>
      </div>
      <div className={classes.statisticsRow}>
        <span>Business</span> <span>{route.BUSINESS_C}</span>
      </div>
      <div className={classes.separator} />
      <div className={classes.statisticsRow}>
        <span>Total addresses (exc. businesses)</span> <span>{route.TOTAL_COUN - route.BUSINESS_C}</span>
      </div>
    </div>
  ) : null;
};
