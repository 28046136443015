/* eslint-disable react/no-unknown-property */
import { useState, useEffect, useMemo } from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { CAMPAIGN_ENUM_TYPES } from "shared/constants";

import { convertNumber } from "../../../../pages/campaign/quote/container/utils";

import { style } from "./style";
import { useStore } from "../../../../store";
import { StoreType } from "../../../../store/types";

const useStyles = makeStyles(() => style);

type ClassDictionary = {
  [id: string]: any;
};

type BadgeProps = {
  totalClassName?: string | number | ClassDictionary;
  forceOpen?: boolean;
};

export const Badge = ({ totalClassName = {}, forceOpen = false }: BadgeProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [vibrateBadge, setVibrateBadge] = useState(false);

  const {
    country,
    costsCalculationData,
    campaign: { isD2D, channel },
    missions,
  }: StoreType = useStore();

  const isUS = country.name === "United States";
  const isDM = channel === CAMPAIGN_ENUM_TYPES.DIRECTMAIL;

  const {
    detailedCost: { subtotal, printing, outfit },
    costPerFlyer,
    flyersAmount,
  } = costsCalculationData;

  const flyersCount = useMemo(
    () => (isDM ? flyersAmount : printing?.quantity ? printing?.quantity : "-"),
    [isDM, printing, flyersAmount]
  );

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const currency = country?.currencySign ? country.currencySign : "$";

  useEffect(() => {
    setOpen(forceOpen);
  }, [forceOpen]);

  useEffect(() => {
    setVibrateBadge(true);
    setTimeout(() => {
      setVibrateBadge(false);
    }, 300);
  }, [subtotal]);

  return (
    <Tooltip
      classes={{ popper: classes.popper, tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
      arrow
      placement="bottom-start"
      open={open}
      cypress_id="costTooltip"
      title={
        <Box>
          {!isDM ? (
            <Box className={classes.tooltipRow}>
              <Typography className={classes.tooltipRowTitle}>{isD2D ? "Areas" : "Distributors"}</Typography>
              <Typography className={classes.tooltipRowValue}>{missions.length || "-"}</Typography>
            </Box>
          ) : null}

          {outfit?.total ? (
            <Box className={classes.tooltipRow}>
              <Typography className={classes.tooltipRowTitle}>Outfit cost</Typography>
              <Typography className={classes.tooltipRowValue}>
                {currency}
                {(Math.trunc(Number(outfit.total) * 100) / 100).toLocaleString()}
              </Typography>
            </Box>
          ) : null}
          <Box className={classes.tooltipRow}>
            <Typography className={classes.tooltipRowTitle}>{isDM ? "Mail pieces" : "Flyers"}</Typography>
            <Typography className={classes.tooltipRowValue}>{flyersCount}</Typography>
          </Box>
          <Box className={classes.tooltipRow}>
            <Typography className={classes.tooltipRowTitle}>
              {isDM ? "Cost per mail piece" : "Cost per flyer"}
            </Typography>
            <Typography className={classes.tooltipRowValue}>
              {currency}
              {costPerFlyer ? (Math.trunc(Number(costPerFlyer) * 1000) / 1000).toFixed(3) : 0}
            </Typography>
          </Box>
          <Box className={classes.divideLine} />
          <Box className={classes.tooltipRow}>
            <Box>
              <Typography className={classes.tooltipRowTitle}>Total Cost</Typography>
            </Box>
            <Typography className={`${classes.tooltipRowValue} ${classes.totalCostValue}`} style={{ margin: "auto 0" }}>
              {subtotal
                ? convertNumber({
                    number: subtotal,
                    currency: currency,
                    isUSClient: isUS,
                  })
                : `${currency}0`}
            </Typography>
          </Box>
          <Typography className={classes.tooltipRowSubTitle}>
            * Include flyer printing, distribution, travel, and other costs.
          </Typography>
          <Typography className={classes.tooltipRowSubTitle}>* No taxes included.</Typography>
        </Box>
      }
    >
      <Button
        className={cn(classes.button, totalClassName, { [classes.vibrate]: vibrateBadge })}
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
      >
        {"Cost: "}
        {subtotal
          ? convertNumber({
              number: subtotal,
              currency: currency,
              isUSClient: isUS,
            })
          : `${currency}0`}
      </Button>
    </Tooltip>
  );
};
