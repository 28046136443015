import React from "react";
import { makeStyles } from "@material-ui/core";
import { AddressCard } from "./AddressCard";
import { styles } from "./styles";

const useStyles = makeStyles(() => styles);

export const AddressBlock = ({ addresses, onAddressCardClick, handleDeleteLocation }) => {
  const classes = useStyles();
  return addresses && addresses.length ? (
    <div className={classes.addressesContainer}>
      <div className={classes.title}>Addresses</div>
      {addresses.map((address) => {
        return (
          <AddressCard
            address={address}
            key={address.name}
            onClick={() => onAddressCardClick(address)}
            handleDeleteLocation={handleDeleteLocation}
          />
        );
      })}
    </div>
  ) : null;
};
