const style = {
  inputName: {
    color: "#707087",
  },
  wrongDate: {
    fontStyle: "italic",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#f50057",
    letterSpacing: "-0.01em",
    textAlign: "center",
    margin: "8px 0 -16px 0",
  },
  hintIcon: {
    height: "12px",
    width: "12px",
    marginLeft: "4px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  editSaveButton: {
    cursor: "pointer",
    "& p": {
      fontWeight: "bold",
      fontSize: "14px",
    },
  },
  saveNameButton: { color: "#EB1616" },
  font_size_14: {
    fontSize: 14,
  },
  required: {
    color: "#FF0000",
    fontSize: "12px",
  },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "calc(100vh - 140px)",
  },
  h2hDateErrorContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFE4E9",
    padding: "10px",
    marginTop: "10px",
  },
  h2hDateErrorTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  h2hDateErrorTitle: {
    color: "#EB1616",
    fontWeight: "bold",
  },
  h2hDateErrorCtaButton: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  h2hDateErrorMessage: {
    marginTop: "10px",
  },
  error: {
    marginTop: "8px",
    color: "#F90606",
  },
  qrCheckboxContainer: {
    display: "flex",
    alignItems: "center",
  },
  qrCheckbox: {
    padding: "7px 0",
  },
  qrCheckboxMessage: {
    marginLeft: "5px",
  },
  commentsInput: { width: "100%", marginBottom: "5px" },
  datePicker: {
    width: "100%",
  },
  relative: {
    position: "relative",
  },
  link: {
    color: "#EE4360",
    textDecorationLine: "underline",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    cursor: "pointer",
  },
};

export default style;
