export const styles = {
  statisticsBlockContainer: {
    position: "absolute",
    right: "24px",
    top: "126px",
    borderRadius: "8px",
    background: "#FFFFFF",
    width: "380px",
    height: "242px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  statisticsHeaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    fontWeight: 700,
    fontFamily: "'Proxima Nova', 'sans-serif'",
    fontSize: "18px",
    marginBottom: "5px",
  },
  statistics: {
    fontWeight: 700,
    fontFamily: "'Proxima Nova', 'sans-serif'",
    fontSize: "16px",
    marginBottom: "5px",
  },
  statisticsRow: {
    display: "flex",
    fontFamily: "'Proxima Nova', 'sans-serif'",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  separator: {
    width: "100%",
    height: "1px",
    background: "#AEAFC7",
    marginBottom: "5px",
  },
};
