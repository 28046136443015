import { ComponentType } from "react";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";

const styles = () => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "4px 4px 8px 0px rgba(34, 60, 80, 0.2)",
    fontSize: 11,
    borderRadius: "6px",
  },
});

const LightTooltip = withStyles(styles)(Tooltip) as ComponentType<TooltipProps & Partial<WithStyles<typeof styles>>>;

export { LightTooltip };
