import { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import cn from "classnames";
import { convertNumberRounded } from "../../container/utils";
import { ApplyButton } from "./ApplyButton";
import { CouponCodeInput } from "./CouponCodeInput";
import { style } from "./style";

const useStyles = makeStyles(() => style);

enum CouponCodeStatusEnum {
  NONE = "NONE",
  INVALID = "INVALD",
  VALID = "VALID",
}

type CouponCodeProps = {
  stripeCouponCode?: string;
  amountOff?: number;
  percentOff?: number;
  isDraft?: boolean;
  currencySign?: string;
  isUSClient?: boolean;
  errorMessage?: string;
  setCouponErrorMessage: (a: string) => void;
  onApply?: ({ stripeCouponCode, saveLogs }: { stripeCouponCode: string; saveLogs: boolean }) => Promise<boolean>;
};

export const CouponCode = ({
  stripeCouponCode = "",
  amountOff = 0,
  percentOff = 0,
  isDraft = true,
  currencySign = "#",
  isUSClient = true,
  errorMessage = "",
  setCouponErrorMessage,
  onApply = () => Promise.resolve(false),
}: CouponCodeProps) => {
  const classes = useStyles();
  const [tempCouponCode, setTempCouponCode] = useState("");
  const [tempAmountOff, setTempAmountOff] = useState(0);
  const [tempPercentOff, setTempPercentOff] = useState(0);
  const [couponCodeStatus, setCouponCodeStatus] = useState<CouponCodeStatusEnum>(CouponCodeStatusEnum.NONE);
  const [isApplying, setIsApplying] = useState(false);

  const isValid = couponCodeStatus === CouponCodeStatusEnum.VALID;
  const canApply = isDraft && couponCodeStatus !== CouponCodeStatusEnum.VALID;

  useEffect(() => {
    /**
     * if couponCode from parent is not empty, it means that this is valid coupon code,
     * because we should store only valid coupon code in the db
     */
    if (stripeCouponCode) {
      setTempCouponCode(stripeCouponCode);
      setCouponCodeStatus(CouponCodeStatusEnum.VALID);
      setTempAmountOff(amountOff);
      setTempPercentOff(percentOff);
    } else {
      setTempCouponCode("");
      setCouponCodeStatus(CouponCodeStatusEnum.NONE);
      setTempAmountOff(0);
      setTempPercentOff(0);
    }
  }, [stripeCouponCode, percentOff, amountOff]);

  const handleChangeCouponCode = (val: string) => {
    /**
     * Once we applied valid coupon code, we can only clear input by using clear button
     * In case invalid coupon code, remove the error text below the input
     */
    if (couponCodeStatus !== CouponCodeStatusEnum.VALID) {
      setTempCouponCode(val);
      setCouponCodeStatus(CouponCodeStatusEnum.NONE);
    }
  };

  const handleClearCouponCode = async () => {
    /**
     * (couponCode is not empty) = (couponCodeStatus === CouponCodeStatusEnum.VALID)
     * if we clear the valid coupon code, we should also clear the db
     */
    if (stripeCouponCode) {
      await onApply({ stripeCouponCode: "", saveLogs: true });
    }
    setCouponErrorMessage("");
    setTempCouponCode("");
    setCouponCodeStatus(CouponCodeStatusEnum.NONE);
  };

  const handleApply = async () => {
    if (!tempCouponCode) {
      return;
    }

    setIsApplying(true);
    const result = await onApply({ stripeCouponCode: tempCouponCode, saveLogs: true });
    if (!result) {
      setCouponCodeStatus(CouponCodeStatusEnum.INVALID);
    } else {
      setCouponErrorMessage("");
    }
    setIsApplying(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <CouponCodeInput
          disabled={!isDraft}
          value={tempCouponCode}
          onChange={handleChangeCouponCode}
          onClear={handleClearCouponCode}
        />
        {canApply && (
          <div className={classes.applyButton}>
            <ApplyButton loading={isApplying} onClick={handleApply} />
          </div>
        )}
        {isValid && (
          <div className={classes.wrapperText}>
            <Typography className={classes.amountOffText}>
              -
              {convertNumberRounded({
                number: tempAmountOff || 0,
                currency: currencySign,
                fixed: 2,
                isUSClient: isUSClient,
              })}
            </Typography>
          </div>
        )}
      </div>
      {!!errorMessage && !isValid && (
        <Typography className={cn(classes.belowText, classes.errorText)}>{errorMessage}</Typography>
      )}
      {isValid && (
        <Typography className={cn(classes.belowText, classes.percentOffText)}>
          {tempPercentOff ? `${Math.trunc(Number(tempPercentOff) || 0)}% off` : "coupon"} applied
        </Typography>
      )}
    </div>
  );
};

export default CouponCode;
