import React from "react";
import { Typography, Box, makeStyles } from "@material-ui/core";
import { PlaceAutocomplete } from "components/place-autocomplete";
import { styles } from "./styles";
import { onAutocompleteSelect } from "../../utils";
import { useStore } from "../../../../store";

const useStyles = makeStyles(() => styles);

export const MapHeader = ({ city, countryCode, isSubmitted, postCodeSearch = false, isLoadingMap = false }) => {
  const classes = useStyles();

  const {
    errors: { costsCalculationError },
    campaign: { isD2D },
    map: { visiblePostCodes = [] },
    updateMap,
  } = useStore();

  const placeholder = "Search for a specific location or address...";

  const getFilteredPostCodes = (key) => [...visiblePostCodes.filter((postCode) => postCode?.name?.includes(key))];

  const handlePlaceChanged = (newMarker) => {
    onAutocompleteSelect({ newMarker, updateMap, isD2D });

    if (newMarker.isPostcode) {
      updateMap({ selectedPostCode: newMarker.postCode });
    }
  };

  return (
    <div className={classes.mapHeader}>
      <Typography variant="" className={classes.cityName} cypress_id={"cityName"}>
        {city?.name}
      </Typography>
      <Box className={classes.mapControlsWrapper}>
        <Box className={classes.placeAutocomplete} cypress_id={"locationsSearchInput"}>
          <PlaceAutocomplete
            restrictedIds={city?.addresses?.map((address) => address.id) || []}
            city={city}
            countryCode={countryCode}
            disabled={!!costsCalculationError || isSubmitted || isLoadingMap}
            placeholder={placeholder}
            postCodeSearch={postCodeSearch}
            onPlaceChanged={handlePlaceChanged}
            getFilteredPostCodes={getFilteredPostCodes}
          />
        </Box>
        {/* <div className={classes.buttonsWrapper}>
          {buttons.map((button, index, buttons) => {
            return (
              <>
                <MapButton
                  key={button.type}
                  active={button.active}
                  type={button.type}
                  onClick={() => handleTooltipClick(button.mapType)}
                  disabled={button.disabled}
                />
                {index + 1 !== buttons.length && <div className={classes.separator} />}
              </>
            );
          })}
        </div> */}
      </Box>
      {/* //PAD-10964 Remove Undo/Redo for D2D/H2H Audience page */}
      {/*  */}
      {/* <Box> */}
      {/*   <IfElse condition={undoState?.length}> */}
      {/*     <UndoActiveIcon */}
      {/*       className={cn(classes.activeUndoRedo, classes.mr_10)} */}
      {/*       onClick={() => handleUndo({ undoState, redoState, selectedCities, setState })} */}
      {/*     /> */}
      {/*     <UndoIcon className={classes.mr_10} /> */}
      {/*   </IfElse> */}
      {/*   <IfElse condition={redoState?.length}> */}
      {/*     <RedoActiveIcon */}
      {/*       className={classes.activeUndoRedo} */}
      {/*       onClick={() => */}
      {/*         handleRedo({ */}
      {/*           selectedCities, */}
      {/*           redoState, */}
      {/*           undoState, */}
      {/*           setState, */}
      {/*         }) */}
      {/*       } */}
      {/*     /> */}
      {/*     <RedoIcon /> */}
      {/*   </IfElse> */}
      {/* </Box> */}
    </div>
  );
};
