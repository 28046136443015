import { useState } from "react";
import cn from "classnames";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { Autocomplete } from "components/form";
import { If, IfElse } from "components/logic";
import { DeleteButton } from "components/audience-form/components/campaign-location/h2h-precise-locations/components/DeleteButton";
import { AreaCard } from "./components/AreaCard";

import { fetchCityDistributionZones } from "../utils";

import style from "./style";
import { getCampaignCost, getCityTimezone } from "../../../../../graphQL";
import { getCorrectSettingsObject } from "../../../../../shared/campaign";
import { useStore } from "../../../../../store";

const LetterboxAreas = ({ classes, isSubmitted }) => {
  const {
    costsCalculationData,
    country,
    campaign,
    city,
    map: { loading },
    updateCostsCalculationData,
    setCity,
    updateCity,
    updateMap,
    setTimezone,
    hoveredMission,
    setHoveredMission,
  } = useStore();

  const { countryDefaultSettings, citiesSettings, citiesList } = country;
  const { isD2D, id: campaignId } = campaign;

  const [showCitySelect, setShowCitySelect] = useState(false);

  const handleCityChange = async (city) => {
    setShowCitySelect(false);
    updateMap({ loading: true, loadingPercentage: null });
    const cityDistributionZones = await fetchCityDistributionZones(city.id);

    const newCity = {
      ...city,
      isAddressesPopupOpened: true,
      distributionZones: cityDistributionZones[isD2D ? "d2d" : "h2h"],
    };

    const detailedCost = await getCampaignCost({
      id: campaignId,
      cityId: city.id,
    });

    const citySetting = citiesSettings.find((citySetting) => citySetting.id == city.id);

    const settings = getCorrectSettingsObject({
      countrySettings: countryDefaultSettings,
      citySetting,
      isD2D,
    });

    const cityTimezone = await getCityTimezone(city.id);
    if (cityTimezone) {
      setTimezone(cityTimezone);
    }

    updateCostsCalculationData({
      settings,
      detailedCost,
    });
    updateMap({ center: { lat: newCity.lat, lng: newCity.lng }, zoom: 12, loading: false });

    setCity(newCity);
  };

  return (
    <Box item xs={12} className={classes.h2hCampaignLocationWrapper}>
      <Box display="flex" alignItems="flex-start">
        <Typography className={classes.city}>City{city ? ":" : ""}</Typography>
        <If condition={showCitySelect || !city}>
          <Box className={classes.citiesAutocomplete}>
            <Autocomplete
              name="cities"
              label="Select a city"
              value={null}
              classes={{ option: classes.optionSelect }}
              getOptionLabel={(item) => item.nameWithState}
              getOptionSelected={(option, value) => option.nameWithState === value.nameWithState}
              options={citiesList}
              onChange={(city) => {
                setShowCitySelect(false);
                handleCityChange(city);
              }}
              hideLabel
              disabled={isSubmitted || loading}
            />
          </Box>
        </If>

        {city ? (
          <If condition={city.id}>
            <Box display="flex" alignItems="center" style={{ marginLeft: "10px" }}>
              <IfElse condition={city.showDeleteCityPopup}>
                <Typography component="div" className={classes.selectedCityInputDelete}>
                  <Typography component="span">{`Delete ${city.nameWithState}?`}</Typography>
                  <Box display="flex">
                    <div
                      className={classes.deleteAddressConfirmBtn}
                      onClick={() => {
                        setCity(null);
                      }}
                    >
                      {"Yes"}
                    </div>
                    <div
                      className={classes.deleteAddressDeclineBtn}
                      onClick={() => {
                        updateCity({ showDeleteCityPopup: false, isCityOptionsOpened: false });
                      }}
                    >
                      {"No"}
                    </div>
                  </Box>
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography
                    component="div"
                    className={cn(classes.selectedCityInput, {
                      [classes.disabledCity]: !city.isVisible,
                      [classes.disabledCityOnSubmit]: isSubmitted,
                    })}
                  >
                    {city.nameWithState}
                  </Typography>
                  {!isSubmitted && !loading && (
                    <DeleteButton
                      className={classes.deleteCityButton}
                      onClick={() =>
                        updateCity({
                          showDeleteCityPopup: true,
                          isCityOptionsOpened: false,
                        })
                      }
                    />
                  )}
                </Box>
              </IfElse>
            </Box>
          </If>
        ) : null}
      </Box>
      {city && city.addresses?.length > 0 ? (
        <div className={classes.totalsWrapper}>
          <Box className={classes.distributorsAndFlyers}>
            <Typography className={classes.totals}>
              <span>Areas:</span>
              <span className={classes.totalValue}>{city.addresses?.length}</span>
            </Typography>
            <Typography className={classes.totals}>
              <span>Flyers:</span>
              <span className={classes.totalValue}>{costsCalculationData?.detailedCost?.printing?.quantity || 0}</span>
            </Typography>
          </Box>
        </div>
      ) : (
        <Box />
      )}

      {city ? (
        <Grid xs={12} container item justify="flex-end" position="relative" style={{ height: "100%" }}>
          <Box className={city.id ? classes.cityBox : undefined}>
            <If condition={city.id}>
              <Box display="flex" flexDirection="column" height="100%">
                {city.id && city.isAddressesPopupOpened ? (
                  <Box className={classes.addressesWrapper}>
                    {city.addresses.map((address, index, addresses) => (
                      <AreaCard
                        key={address.id}
                        area={address}
                        index={index}
                        addresses={addresses}
                        isSubmitted={isSubmitted}
                        hoveredMission={hoveredMission}
                        setHoveredMission={setHoveredMission}
                      />
                    ))}
                  </Box>
                ) : null}
              </Box>
            </If>
          </Box>
        </Grid>
      ) : null}
    </Box>
  );
};

export default withStyles(style)(LetterboxAreas);
