import { HEADER_TITLE, HELP_URL } from "pages/constants";

export const FAQClickEDDM = (route: string): string => {
  switch (route) {
    case "segments":
      return HELP_URL[HEADER_TITLE.CAMPAIGN_AUDIENCE];

    case "upload":
      return HELP_URL[HEADER_TITLE.UPLOAD_DESIGN];

    case "details":
      return HELP_URL[HEADER_TITLE.CAMPAIGN_DETAILS];

    case "submit":
      return HELP_URL[HEADER_TITLE.SUBMIT];

    default:
      return "https://selfserve.oppizi.com/";
  }
};
