/* eslint-disable react/no-unknown-property */
import { withStyles } from "@material-ui/core";
import React, { memo, ReactNode } from "react";
import style from "./style";

interface IButton {
  title: string;
  cypressId: string;
  description: string;
  image: ReactNode;
  action: () => void;
  classes: any;
}

const Button: React.FC<IButton> = ({ classes, title, cypressId, description, image, action }) => {
  return (
    <div className={classes.button} onClick={action} cypress_id={cypressId}>
      <div className={classes.image}>{image}</div>
      <div className={classes.title}>{title}</div>
      <div className={classes.description}>{description}</div>
    </div>
  );
};

export default memo(withStyles(style)(Button));
