export const VisibilityIcon = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.625 10C18.625 10 14.9873 15.625 10.5 15.625C6.01269 15.625 2.375 10 2.375 10C2.375 10 6.01269 4.375 10.5 4.375C14.9873 4.375 18.625 10 18.625 10Z"
      stroke="#273754"
      strokeWidth="2"
    />
    <circle cx="10.5" cy="10" r="3.125" stroke="#273754" strokeWidth="2" />
  </svg>
);
