import React, { useCallback, useMemo, useState } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";
import { LocationIcon } from "static/media/location-icon";
import { DatePicker } from "components/date-picker";
import { handleMapItemClick } from "components/audience-map/utils";
import { DeleteButton } from "components/audience-form/components/campaign-location/h2h-precise-locations/components/DeleteButton";
import { convertStringSeperatedBySpecialToArray, convertArrayToStringSeperatedBySpecial } from "utils";
import { Icon } from "components/icons";
import { styles } from "./styles";
import { Mission } from "./Mission";
import {
  // handleAddressOptionsClick,
  // handlePopoverClose,
  // getLocationPopupOptions,
  handleMissionDelete,
  isInvalidMission,
  handleAddressDelete,
} from "../../../utils";
import { useStore } from "../../../../../../../store";

const useStyles = makeStyles(() => styles);

export const PreciseLocation = ({ location, setAnchorEl, isSubmitted }) => {
  const classes = useStyles();
  const [deletingLocation, setDeletingLocation] = useState(false);

  const {
    city,
    campaign,
    client: { id: clientId },
    timezone,
    country: {
      baPerLocation: baNumsPerLocation,
      countryDefaultSettings: { defaultCampaignLimits },
    },
    costsCalculationData: { detailedCost },
    updateMap,
    setAddresses,
    updateCity,
  } = useStore();

  const { distribution, printing } = detailedCost;

  const { startDates, times } = useMemo(() => {
    const startDates = [];
    const times = [];

    const tmpDates = convertStringSeperatedBySpecialToArray(location.startDate);
    const tmpTimes = convertStringSeperatedBySpecialToArray(location.time);
    const len = tmpDates.length;

    if (tmpTimes.length === len) {
      const ID = [];
      const dateValues = tmpDates.map((date, index) => {
        ID[index] = index;
        return new Date(date).getTime();
      });

      ID.sort((a, b) => {
        if (dateValues[a] < dateValues[b]) return -1;
        if (dateValues[a] > dateValues[b]) return 1;
        return Number(tmpTimes[a]) < Number(tmpTimes[b]) ? -1 : 1;
      });

      ID.forEach((id) => {
        startDates.push(tmpDates[id]);
        times.push(tmpTimes[id]);
      });
    }

    return { startDates, times };
  }, [location.startDate, location.time]);

  const isExistMissions = useMemo(
    () => startDates && times && startDates.length && startDates.length === times.length,
    [startDates, times]
  );

  const oldDates = useMemo(
    () =>
      city.addresses.reduce((dates, address) => {
        const tmpDates = convertStringSeperatedBySpecialToArray(address.startDate);
        const tmpTimes = convertStringSeperatedBySpecialToArray(address.time);

        if (tmpDates.length === tmpTimes.length) {
          tmpDates.forEach((d) => dates.push(new Date(d)));
        }

        return dates;
      }, []),
    [city]
  );

  const existInvalidMissionOnThisLocation = useMemo(
    () =>
      startDates.reduce((acc, d) => {
        if (isInvalidMission(d, timezone, defaultCampaignLimits)) {
          acc = true;
        }
        return acc;
      }, false),
    [startDates]
  );

  const getUpdatedAddresses = ({ city, newStartDates, newTimes }) =>
    city.addresses.reduce((acc1, address) => {
      if (address.id !== location.id) return [...acc1, address];

      return [
        ...acc1,
        {
          ...address,
          startDate: convertArrayToStringSeperatedBySpecial(newStartDates),
          time: convertArrayToStringSeperatedBySpecial(newTimes),
        },
      ];
    }, []);

  const handleConfirm = useCallback(
    (selectedDates, selectedTime) => {
      const newStartDates = [...startDates, ...selectedDates];
      const newTimes = [...times, ...Array(selectedDates.length).fill(selectedTime)];

      const updatedAddresses = getUpdatedAddresses({ city, newStartDates, newTimes });

      setAddresses(updatedAddresses);
    },
    [startDates, times, city]
  );

  const handleUpdateTime = useCallback(
    (index, newTime) => {
      const newStartDates = [...startDates];
      const newTimes = [...times.slice(0, index), newTime, ...times.slice(index + 1, times.length)];

      const updatedAddresses = getUpdatedAddresses({ city, newStartDates, newTimes });
      setAddresses(updatedAddresses);
    },
    [startDates, times, location, city]
  );

  const handleDeleteMission = useCallback(
    (index) => {
      const newStartDates = [...startDates.slice(0, index), ...startDates.slice(index + 1, startDates.length)];
      const newTimes = [...times.slice(0, index), ...times.slice(index + 1, times.length)];

      handleMissionDelete({
        city,
        location,
        setAddresses,
        newStartDates,
        newTimes,
        setAnchorEl,
      });
    },
    [startDates, times, location, city]
  );

  const handleLocationCardClick = () => {
    if (!location.editMode) {
      handleMapItemClick({ item: location, activeSelectedCity: city, updateMap, setAddresses });
    }
  };

  // const popupOptions = getLocationPopupOptions(location.isVisible);

  const handleDeleteLocation = (event) => {
    event.stopPropagation();

    handleAddressDelete({
      address: location,
      city,
      setAnchorEl,
      updateCity,
      campaignId: campaign.id,
      setDeletingLocation,
      clientId,
    });
  };

  const flyerPack = printing.quantity / distribution.quantity;

  const distributors = startDates.length * baNumsPerLocation;
  const amountFlyers = startDates.length ? startDates.length * flyerPack * baNumsPerLocation : 0;

  return (
    <Box
      className={classnames(classes.locationCardContainer, { [classes.clickable]: !location.editMode })}
      onClick={handleLocationCardClick}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.locationTitle}>
          {existInvalidMissionOnThisLocation || !distributors ? (
            <Icon type="Attention" size="s" />
          ) : (
            <LocationIcon className={classes.locationIcon} />
          )}
          {location.value}
        </Typography>
        {/* <Box className={classes.locationOptionsBtn}>
          <img
            src={MoreVerticalIcon}
            onClick={(event) => {
              event.stopPropagation();
              handleAddressOptionsClick({
                event,
                city,
                address: location,
                setAnchorEl,
                setAddresses,
              });
            }}
            alt="img"
          />
        </Box> */}
        {!isSubmitted && <DeleteButton onClick={handleDeleteLocation} deleting={deletingLocation} />}
        {/* <Menu
          id="address-menu"
          anchorEl={anchorEl}
          open={location.isAddressOptionsOpened || false}
          onClose={() => handlePopoverClose({ setAnchorEl, city, updateCity })}
          disableEnforceFocus
        >
          {popupOptions.map((menuItem) => {
            const { Icon } = menuItem;
            return (
              <MenuItem
                key={menuItem.value}
                onClick={() =>
                  handleMenuItemClick({
                    address: location,
                    menuItem: menuItem.value,
                    city,
                    addressId: location.id,
                    setAnchorEl,
                    updateCity,
                    setAddresses,
                  })
                }
                value={menuItem.value}
              >
                <Icon /> <span style={{ marginLeft: "5px" }}>{menuItem.label}</span>
              </MenuItem>
            );
          })}
        </Menu> */}
      </Box>
      {location.editMode ? (
        <>
          <Box>
            <Typography className={classes.clickButtonHint}>
              Click “Add date and time” to create a distribution at this location
            </Typography>
          </Box>
          {isExistMissions === true && (
            <Box className={classes.missionsListContainer}>
              <Box className={classes.missionContainer}>
                <Typography className={classes.missionTitle}>Date</Typography>
                <Typography className={classes.missionTitle}>Time</Typography>
                <Typography className={classes.missionTitle} style={{ textAlign: "center", color: "#707087" }}>
                  Distributors
                </Typography>
                <Typography className={classes.missionTitle} style={{ textAlign: "center", color: "#707087" }}>
                  Flyers
                </Typography>
                <Typography />
              </Box>
              {startDates.map((d, index) => (
                <Mission
                  key={index}
                  startDate={d}
                  time={times[index]}
                  baNumsPerLocation={baNumsPerLocation}
                  flyersAmountByMission={flyerPack}
                  onUpdateTime={(newTime) => handleUpdateTime(index, newTime)}
                  onDeleteMission={() => handleDeleteMission(index)}
                  timezone={timezone}
                  isSubmitted={isSubmitted}
                  defaultCampaignLimits={defaultCampaignLimits}
                />
              ))}
            </Box>
          )}

          <Box
            className={classes.bottomContainer}
            justifyContent={isExistMissions ? "flex-start" : "center"}
            style={isSubmitted ? { display: "none" } : {}}
          >
            <DatePicker
              oldDates={oldDates}
              onConfirm={handleConfirm}
              timezone={timezone}
              defaultCampaignLimits={defaultCampaignLimits}
            />
          </Box>
        </>
      ) : (
        <Box display={"flex"}>
          <Typography className={classnames(classes.missionsCount, { [classes.nonMissions]: !distributors })}>
            Distributors: <span className={classes.totalMissions}>{distributors}</span>
          </Typography>
          <Typography className={classnames(classes.missionsCount, { [classes.nonMissions]: !distributors })}>
            Flyers: <span className={classes.totalMissions}>{amountFlyers}</span>
          </Typography>
        </Box>
      )}
    </Box>
  );
};
