import { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { Box, makeStyles } from "@material-ui/core";
import CONFIG from "config/config";
import { MAP_TYPES, radiusToZoom } from "pages/constants";
import useOverlaysFetch from "Hooks/useOverlaysFetch.js";
import { LoadingScreen } from "components/audience-map/components/LoadingScreen";
import { styles } from "./styles.js";
import { MAP_EXTRA_STYLES } from "../../options";
import { SummaryBlock } from "./components/SummaryBlock";
import { RouteStatistics } from "./components/RouteStatistics";
import { AddressBlock } from "./components/AddressesBlock";
import { useEDDMCostsCalculation } from "../../../../Hooks/useEDDMCostsCalculation";
import {
  onMapInit,
  handleScheduleMeeting,
  renderPolygonsOnEddmMap,
  handleHoverSelectedMissionUpdate,
} from "../../utils.js";
import { useStore } from "../../../../store";

const useStyles = makeStyles(() => styles);

export const AudienceMapEDDM = ({
  isSubmitted,
  radius,
  setRadius,
  selectedRoutes,
  setSelectedRoutes,
  shouldAutoSave,
  setShouldAutoSave,
}) => {
  const classes = useStyles();
  const {
    country,
    map: { center, zoom, loading, loadingPercentage },
    campaign: { campaignName },
    updateMap,
    hoveredRoute,
    setHoveredRoute,
    distributionLocations,
    setDistributionLocations,
    setFocusedLocation,
  } = useStore();
  const { recalculateEDDMCampaignCost } = useEDDMCostsCalculation();

  const focusedLocation = useMemo(
    () => distributionLocations.find((location) => location.isFocused),
    [distributionLocations]
  );

  const { loadData } = useOverlaysFetch({ countryCode: country.code, disabled: false });
  const drawingMode = null;
  // const [drawingManager, setDrawingManager] = useState(null);
  const [routes, setRoutes] = useState({});
  const [googleMapKey, setGoogleMapKey] = useState(new Date().getTime());
  const [googleMap, setGoogleMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: CONFIG.GOOGLE_API_KEY,
    libraries: ["geometry", "places", "drawing"],
    language: "en",
  });

  const mapRef = useRef(null);

  const geocoder = useMemo(
    () => (window?.google?.maps?.Geocoder ? new window.google.maps.Geocoder() : null),
    [window.google, isLoaded]
  );
  // useEffect(() => {
  //   if (drawingManager) {
  //     drawingManager.setDrawingMode(drawingMode);
  //   }
  // }, [drawingMode, drawingManager]);

  useEffect(() => {
    if (geocoder && googleMap && !Object.values(routes).length) {
      geocoder.geocode({ address: country.name }, (results, status) => {
        if (status === "OK") {
          googleMap.setCenter(results[0].geometry.location);
          updateMap({ zoom: 5 });
          // googleMap.fitBounds(results[0].geometry.viewport);
        }
      });
    }
  }, [geocoder, googleMap]);

  useEffect(() => {
    handleHoverSelectedMissionUpdate({ mapInit: googleMap, hoveredMission: hoveredRoute?.id || "" });
  }, [hoveredRoute]);

  useEffect(() => {
    if (Object.values(routes).length) {
      // LOOK FOR ALL THE SELECTED ROUTES IN NON FOCUSED LOCATION
      const selectedRoutesFromNonFocusedLocations = distributionLocations.reduce((acc, curr) => {
        if (curr.isFocused) {
          return acc;
        }
        return [...acc, ...curr.selectedRoutes];
      }, []);

      // LOOK FOR THE SELECTED ROUTES THAT ARE PART OF THE ROUTES FETCHED FOR THE CURRENT FOCUSED LOCATIONS
      // AND REMOVE THE ROUTES THAT ARE ALREADY SELECTED IN SOME OTHER LOCATION
      const selectedRoutesOnCurrentFocusedLocation = Object.values(routes)
        .reduce((acc, curr) => {
          const selectedRoute = selectedRoutes.find((r) => r.id === curr.id);
          if (selectedRoute) {
            acc.push(selectedRoute);
          }
          return acc;
        }, [])
        .filter((r) => !selectedRoutesFromNonFocusedLocations.find((route) => route.id === r.id));

      // SAVE SELECTED ROUTES ON FOCUSED LOCATION AND REMOVE THE UNSELECTED ROUTES FROM OTHER LOCATIONS
      setDistributionLocations(
        distributionLocations.map((l) => {
          if (l.isFocused) {
            return { ...l, selectedRoutes: selectedRoutesOnCurrentFocusedLocation };
          } else {
            return {
              ...l,
              selectedRoutes: l?.selectedRoutes?.filter((r) => selectedRoutes.find((route) => route.id === r.id)) || [],
            };
          }
        })
      );
    }
  }, [selectedRoutes, routes]);

  const fetchRoutes = async ({ layer = "USCarrierRoutesUnflatten", after, previousRoutes = {}, center, radius }) => {
    const includeAddressCount = false;
    const routesResponse = await loadData({
      layer,
      includeAddressCount,
      after,
      centerSphere: center,
      radius,
    });

    if (!routesResponse) {
      setRoutes({});
      return {};
    }

    updateMap({
      loadingPercentage: ((Object.keys(previousRoutes).length * 100) / routesResponse.totalCount).toFixed(),
    });
    if (routesResponse?.pageInfo?.hasNextPage) {
      return await fetchRoutes({
        center,
        radius,
        layer,
        after: routesResponse.pageInfo.endCursor,
        previousRoutes: { ...previousRoutes, ...routesResponse.data },
      });
    } else {
      updateMap({
        loadingPercentage: null,
        loading: false,
      });
      setRoutes({ ...previousRoutes, ...routesResponse.data });
      return { ...previousRoutes, ...routesResponse.data };
    }
  };

  useEffect(() => {
    // SEARCH NEW ROUTES DEPENDING ON SEARCHED LOCATION AND SELECTED RADIUS
    if (focusedLocation && radius) {
      updateMap({ loading: true, center: { lat: focusedLocation.lat, lng: focusedLocation.lng } });
      fetchRoutes({ center: [focusedLocation.lng, focusedLocation.lat], radius: radius * 1609.34 });
    }
  }, [radius, focusedLocation?.name]);

  useEffect(() => {
    if (routes && Object.values(routes).length && googleMap) {
      renderPolygonsOnEddmMap({
        googleMap,
        routes,
        handleRouteSelect,
        selectedRoutes,
        isSubmitted,
        setHoveredRoute,
      });
      updateMap({ zoom: radiusToZoom[radius] });
    }
  }, [googleMap]);

  useEffect(() => {
    // RESET GOOGLE MAP ON EVERY ROUTES FETCH
    if (focusedLocation?.selectedRoutes?.length && Object.values(routes).length) {
      const selectedLocationsToDeleteInFocusedLocation = focusedLocation.selectedRoutes.filter(
        (route) => !Object.values(routes).find((r) => r.id === route.id)
      );
      if (selectedLocationsToDeleteInFocusedLocation.length) {
        setSelectedRoutes((selectedRoutes) => {
          return selectedRoutes.filter(
            (route) => !selectedLocationsToDeleteInFocusedLocation.find((r) => r.id === route.id)
          );
        });
      }
    }
    setGoogleMap(null);
    setGoogleMapKey(new Date().getTime());
  }, [routes]);

  useEffect(() => {
    recalculateEDDMCampaignCost();
    if (!distributionLocations?.length) {
      setRoutes({});
    }
  }, [distributionLocations]);

  const handleRouteSelect = ({ routeId, paths, selected, metadata }) => {
    if (isSubmitted) {
      return;
    }
    setShouldAutoSave(true);
    setSelectedRoutes((routes) => {
      const currentMissionIndex = routes.findIndex((r) => r.id === routeId);
      if (selected && currentMissionIndex === -1) {
        return [
          ...routes,
          {
            id: routeId,
            paths,
            type: MAP_TYPES.POLYGON,
            metadata,
          },
        ];
      } else if (!selected && currentMissionIndex !== -1) {
        return routes.toSpliced(currentMissionIndex, 1);
      } else {
        return routes;
      }
    });
  };

  const onAddressCardClick = (address) => {
    setShouldAutoSave(true);
    setFocusedLocation(address);
    setRadius(address.radius);
  };

  const handleDeleteLocation = (deletedLocation) => {
    setShouldAutoSave(true);
    setDistributionLocations(
      distributionLocations
        .filter((location) => location.name !== deletedLocation.name)
        .map((location, i, array) => {
          if (i + 1 === array.length && deletedLocation.isFocused) {
            setRadius(location.radius);
            return { ...location, isFocused: true };
          } else {
            return location;
          }
        })
    );

    if (deletedLocation?.selectedRoutes?.length) {
      setSelectedRoutes((selectedRoutes) => {
        return selectedRoutes.filter((route) => !deletedLocation?.selectedRoutes.find((r) => r.id === route.id));
      });
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.mapWrapper} ref={(ref) => (mapRef.current = ref)}>
        {loading && (
          <div className={classes.locationsLoading}>
            <LoadingScreen loadingPercentage={loadingPercentage} />
          </div>
        )}
        <GoogleMap
          key={googleMapKey}
          onLoad={(map) => {
            onMapInit({ newMap: map, mapInit: googleMap, setMapInit: setGoogleMap });
          }}
          mapContainerStyle={{
            width: "100%",
            marginLeft: 0,
          }}
          center={center}
          zoom={zoom}
          options={{
            clickableIcons: false,
            zoomControl: true,
            disableDefaultUI: true,
            keyboardShortcuts: false,
            draggable: !drawingMode,
            styles: MAP_EXTRA_STYLES,
          }}
          // onClick={handleMapClick}
        >
          <AddressBlock
            addresses={distributionLocations}
            onAddressCardClick={onAddressCardClick}
            handleDeleteLocation={handleDeleteLocation}
          />
          <SummaryBlock selectedRoutes={selectedRoutes} campaignName={campaignName} />
          <RouteStatistics route={hoveredRoute} />
          {!!distributionLocations.length &&
            distributionLocations.map((location) => {
              return (
                <Marker
                  key={location.name}
                  position={{ lng: location.lng, lat: location.lat }}
                  onClick={() => {
                    setShouldAutoSave(true);
                    setFocusedLocation(location);
                    setRadius(location.radius);
                  }}
                />
              );
            })}
          {/* <DrawingManager
            onLoad={(loadedDrawingManager) => onDrawingManagerLoad({ loadedDrawingManager, setDrawingManager })}
            drawingMode={drawingMode}
            options={BROAD_AREAS_MAP_OPTIONS}
          /> */}
        </GoogleMap>
      </Box>
    </Box>
  );
};
