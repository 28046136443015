import React, { memo } from "react";
import { withStyles } from "@material-ui/core";
import classNames from "classnames";
import { ReactComponent as CloseIcon } from "static/media/close.svg";
import { CancelButton, SaveButton, SkipButton } from "./buttons";
import style from "./style";
import { ClassesType } from "./type";

interface IProps extends ClassesType {
  isOpen: boolean;
  onSave: () => void;
  onClose: () => void;
  onSkip?: () => void;
  loading: boolean;
  title: string;
  body: string;
  customConfirmText?: string;
  hideCancelButton?: boolean;
  centerBody?: boolean;
  submitAutoWidth?: boolean;
  showSkipButton?: boolean;
}

const ConfirmPopup: React.FC<IProps> = ({
  classes,
  isOpen,
  onClose,
  onSave,
  onSkip = () => {},
  loading,
  title,
  body,
  customConfirmText,
  hideCancelButton,
  centerBody,
  submitAutoWidth,
  showSkipButton,
}) => {
  if (!isOpen) {
    return null;
  }

  const buttonsList = () => {
    const buttons: JSX.Element[] = [];

    if (showSkipButton) buttons.push(<SkipButton key="skip" classes={classes} onSkip={onSkip} />);
    buttons.push(
      <SaveButton
        key="save"
        classes={classes}
        customConfirmText={customConfirmText}
        loading={loading}
        submitAutoWidth={submitAutoWidth}
        onSave={onSave}
      />
    );
    if (!hideCancelButton) buttons.push(<CancelButton key="cancel" classes={classes} onClose={onClose} />);

    return showSkipButton ? buttons : buttons.reverse();
  };

  return (
    <div className={classes.modalWrapper}>
      <div
        className={classes.backdropFilter}
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      />
      <div className={classes.modal}>
        <div className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </div>
        <p className={classes.header}>{title}</p>
        <p className={classNames(classes.info, centerBody ? classes.centered : "")}>{body}</p>
        <div>
          <div className={classes.buttons}>{buttonsList()}</div>
        </div>
      </div>
    </div>
  );
};

export default memo(withStyles(style)(ConfirmPopup));
