import ROUTES from "config/routing";
import { authorization } from "module/auth";
import { Fragment, memo, useCallback, useContext, useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import LogRocket from "logrocket";
import CONFIG from "config/config";
import moment from "moment";
import posthog from "posthog-js";
import { CAMPAIGN_INTERNAL_STATUSES } from "pages/constants";
import {
  getCampaign,
  getEDDMSelfServeCampaignData,
  getClient,
  getCountryData,
  getCityTimezone,
  getClientsPricings,
  getEddmCampaignCost,
  sendEmail,
} from "../graphQL";
import useExitPrompt from "../Hooks/useExitPrompt";
import { getDMFlyersAmount } from "./utils";
import { calculateSubtotalAndPrintingCosts, formatPrintingCosts, getDMPrintingCostByFlyer } from "../shared/campaign";
import BounceLoader from "../components/loaders/bounce-loader";
import { CAMPAIGN_ENUM_TYPES } from "../shared/constants";
import { useStore } from "../store";
import { getCitiesList, getLogRocketSessionURL, gtagWrapper, getMobileEmailBody } from "../utils";
import { NonGoogleAlertModal } from "../components/NonGoogleAlertModal";
import { DialogModalContext } from "../components/dialog-modal";
import Chat from "../TalkJS/container";

const DMCampaignDataHOC = (props) => {
  const { initData, updateUser, updateMap } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const logRocketSession = getLogRocketSessionURL({ short: true });
  const [logRocketSent, setLogRocketSent] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [costsError, setCostsError] = useState(false);
  const [country, setCountry] = useState(null);
  const [client, setClient] = useState(null);
  const [isMobile, setIsmobile] = useState(false);
  const [mobileErrorWasRendered, setMobileErrorWasRendered] = useState(false);

  posthog.init(CONFIG.POSTHOG_KEY, {
    api_host: CONFIG.POSTHOG_HOST,
  });

  const runDialogModal = useContext(DialogModalContext);

  const history = useHistory();
  const { campaignId, clientId: clientIdParam } = useParams();
  const user = useMemo(() => authorization.getUser(), [authorization]);

  const getInitialData = useCallback(async () => {
    setIsLoading(true);

    if (user.clientId != clientIdParam) {
      updateUser({ accessDenied: true });
      return history.push(ROUTES.ACCESS_DENIED);
    }

    updateUser({ accessDenied: false });

    if (!campaignId || isNaN(Number(campaignId))) {
      const selectedClientId = user.clientId ? user.clientId : clientIdParam;

      if (!selectedClientId) return history.push(ROUTES.ACCESS_DENIED);

      const clientData = await getClient({ clientId: selectedClientId });

      setClient(clientData[0]);
      const countryId = clientData[0].countryId;
      const country = await getCountryData(countryId);
      const clientsPricings = await getClientsPricings(countryId);
      const formattedClientsPricings = formatPrintingCosts(clientsPricings);

      if (!Object.values(clientsPricings).length) {
        setCostsError(true);
      }

      setCountry(country);

      // const costsCalculationError = getInternalError({
      //   countrySettings: country.countryDefaultSettings,
      //   countryCode: country.code,
      //   printingCost: formattedPrintingCosts,
      // });

      const costsCalculationData = {
        printingCosts: formattedClientsPricings,
      };

      const STATE = {
        campaign: {},
        city: null,
        client: clientData[0],
        distributionLocations: [],
        country: { ...country, baPerLocation: null, citiesList: [] },
        costsCalculationData,
        errors: {},
        user: {
          ...user,
          accessDenied: false,
        },

        missions: [],
      };
      console.log({ STATE });
      initData(STATE);
    } else {
      try {
        const campaignData = await getCampaign(campaignId, user.clientId);

        setClient(campaignData.client);

        const isSubmitted =
          campaignData.internalStatus &&
          campaignData.internalStatus !== CAMPAIGN_INTERNAL_STATUSES.DRAFT &&
          campaignData.internalStatus !== CAMPAIGN_INTERNAL_STATUSES.REJECTED;

        const { distributionLocations } = await getEDDMSelfServeCampaignData(campaignId);
        const countryId = campaignData.country.id;
        const country = await getCountryData(countryId);
        setCountry(country);

        const clientsPricings = await getClientsPricings(countryId);
        const formattedClientsPricings = formatPrintingCosts(clientsPricings);
        const flyersAmount = getDMFlyersAmount(distributionLocations);

        if (!Object.values(clientsPricings).length) {
          setCostsError(true);
        }

        const flyerType = campaignData.flyerType;
        if (!formattedClientsPricings[flyerType] && !isSubmitted) {
          return setCostsError(true);
        }

        const costPerFlyer = getDMPrintingCostByFlyer({
          printingCosts: formattedClientsPricings,
          flyerType,
          flyersAmount,
        });

        let costsCalculationData = {
          printingCosts: formattedClientsPricings,
          costPerFlyer,
          flyersAmount,
          detailedCost: { subtotal: flyersAmount * costPerFlyer },
          settings: { countryTaxRate: country?.countryDefaultSettings?.countryTaxRate || 0 },
        };

        let timezone = "";

        const detailedCost = await getEddmCampaignCost({ id: campaignId, cityId: 0 });

        if (isSubmitted) {
          costsCalculationData = {
            ...costsCalculationData,
            costPerFlyer: campaignData.printingCostPerFlyer,
            detailedCost,
          };
        }

        let convertedStartDate;
        if (campaignData.startDate) {
          // The start_date is saved in UTC but with the actual date in the selected city, NOT the UTC representation with offset.
          // So it is not saved with the offset, but exactly the date that the campaign will launch in the selected city.
          // Since the date pickers and all the calculations related with dates in the front end are using User's tz
          // here we force the date to be in User's timezone in order to calculate all date-related logic as if the user were
          // located in the selected city.
          convertedStartDate = new Date(
            moment(moment(Number(campaignData.startDate)).utc().format().split("T")[0], "YYYY-MM-DD", true).format()
          );
        }

        const campaign = {
          id: campaignId,
          amountOff: campaignData.amountOff,
          campaignComments: campaignData.campaignComments,
          campaignDuration: campaignData.campaignDuration,
          campaignFlyerInfo: campaignData.campaignFlyerInfo,
          campaignName: campaignData.campaignName,
          channel: campaignData.channel,
          stripeCouponCode: campaignData.stripeCouponCode,
          flyerType: campaignData.flyerType,
          flyerWeight: campaignData.flyerWeight,
          internalStatus: campaignData.internalStatus,
          isSubmitted: isSubmitted,
          isPaymentSkipped: campaignData.isPaymentSkipped,
          lastActiveStep: campaignData.lastActiveStep,
          percentOff: campaignData.percentOff,
          qrCodeLink: campaignData.qrCodeLink,
          quote: campaignData.quote,
          startDate: convertedStartDate,
          submittedBy: campaignData.submittedBy,
          updatedAt: campaignData.updatedAt,
          isD2D: campaignData.channel === CAMPAIGN_ENUM_TYPES.LETTERBOX,
          isH2H: campaignData.channel === CAMPAIGN_ENUM_TYPES.HANDTOHAND,
          isDM: campaignData.channel === CAMPAIGN_ENUM_TYPES.DIRECTMAIL,
          subtype: campaignData.subtype,
          //TODO ???? deleeeeeete everything
          missionsCount: campaignData.missionsCount,
          taxes: campaignData.taxes,
          totalCosts: campaignData.totalCosts,
          isTrackableCoupon: campaignData.isTrackableCoupon,
          couponType: campaignData.couponType,
          staticCouponCode: campaignData.staticCouponCode,
          dynamicCouponCode: campaignData.dynamicCouponCode,
        };

        const STATE = {
          campaign,
          client: campaignData.client,
          timezone,
          distributionLocations: distributionLocations || [],
          hoveredRoute: null,
          country: {
            ...country,
            baPerLocation:
              campaignData.internalStatus === CAMPAIGN_INTERNAL_STATUSES.DRAFT
                ? country.countryDefaultSettings.baPerLocation
                : campaignData.baPerLocation,
          },
          costsCalculationData,
          errors: {
            costsCalculationError: "",
            cityMissionsLimitError: { title: "", message: "" },
            qrCodeUrlError: null,
            launchDateError: null,
            campaignNameError: null,
          },
          user: {
            ...user,
            accessDenied: false,
          },
        };
        console.log({ STATE });
        initData(STATE);
      } catch (error) {
        if (error.message && (error.message === "Access denied" || error.message === "Campaign does not exist.")) {
          updateUser({ accessDenied: true });
          history.push(ROUTES.ACCESS_DENIED);
        }
      }
    }
    setIsLoading(false);
  }, [campaignId]);

  useEffect(() => {
    if (CONFIG.ENV === "production" && campaignId !== "channel" && user?.clientId) {
      LogRocket.identify(user.clientId, {
        name: campaignId,
      });
    }

    if (campaignId !== "channel" && logRocketSession && !logRocketSent) {
      const props = {
        event: "ss_logrocket",
        logrocket_session: logRocketSession,
        campaign_id: campaignId,
      };
      gtagWrapper(props);
      setLogRocketSent(true);
    }
  }, [campaignId, logRocketSession, user.clientId]);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      );
    setIsmobile(isMobile);
    if (!openModal && !userAgent.match(/chrome|chromium|crios/i) && !isMobile) {
      setOpenModal(true);
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      getInitialData();
    }
  }, [isLoading]);

  useEffect(() => {
    if (costsError) {
      runDialogModal({
        disabledClose: true,
        title: "System Error",
        contentText: (
          <Box styles={{ padding: "10px 40px", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div />
            <div style={{ textAlign: "center" }}>
              {
                "We apologize for the inconvenience, but the selected 'Flyer Type' is no longer supported. Please reach out to our support team for further assistance."
              }
            </div>
          </Box>
        ),
        ctaLabel: "Contact support",
        customCtaStyles: {
          width: "208px",
        },
        handleCTAClick: () => {
          window.open(
            `mailto:support@oppizi.com?subject=Internal problem with campaign costs&body=Please,%20describe%20your%20problem`
          );
        },
      });
    }
  }, [costsError]);

  useEffect(() => {
    if (isMobile && !mobileErrorWasRendered) {
      setMobileErrorWasRendered(true); // avoid multiple renders
      posthog.capture("Mobile error popup", { userId: user?.id, device: navigator.userAgent });
      runDialogModal({
        title: "Access our campaign creation tool on your desktop!",
        contentText: (
          <Box styles={{ padding: "10px 40px", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div />
            <div style={{ textAlign: "center" }}>
              {
                "Hey there, it looks like you're on mobile. For the best experience, please switch to desktop to create your campaign."
              }
            </div>
          </Box>
        ),
        ctaLabel: "Email me a link",
        customCtaStyles: {
          width: "100%",
        },
        disableCloseOnBlur: true,
        customContentStyles: { padding: "16px" },
        closeSideEffect: () => {
          posthog.capture("Mobile error popup - close button", { userId: user?.id, device: navigator.userAgent });
        },
        handleCTAClick: () => {
          sendEmail({
            input: {
              isHtml: true,
              sender: "contact@oppizi.com",
              recipients: user.email,
              subject: "Continue creating your Oppizi Ads campaign!",
              body: getMobileEmailBody(user.firstName),
              templateName: "oads-layout-body",
            },
          });
          posthog.capture("Mobile error popup - email me a link button", {
            userId: user?.id,
            device: navigator.userAgent,
          });
        },
      });
    }
  }, [isMobile, user, mobileErrorWasRendered]);

  useExitPrompt(
    !!history?.location?.search &&
      !!history?.location?.search?.includes("payment") &&
      !!history?.location?.pathname?.includes("submit")
  );
  return isLoading ? (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100vh">
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>
        <BounceLoader />
        {history?.location?.search &&
          history?.location?.search?.includes("payment") &&
          history?.location?.pathname?.includes("submit") && (
            <div
              style={{
                width: "600px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  textAlign: "center",
                  margin: "20px 0",
                }}
              >
                Hold on a sec, your campaign is still being processed!
              </div>
              <div
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                We're putting the finishing touches on your campaign. To avoid delays, don't close this tab until you
                see a confirmation message. It won't be long!
              </div>
            </div>
          )}
      </div>
    </Box>
  ) : (
    <Fragment>
      <NonGoogleAlertModal open={openModal} closeModal={() => setOpenModal(false)} />
      {props.children}
      <Chat user={user} countryId={country.id} client={client} />
    </Fragment>
  );
};

export default memo(DMCampaignDataHOC);
