import { Icon } from "components/icons";
import {
  StyledAlertContainer,
  StyledAlertHeader,
  StyledAlertTitle,
  StyledAlertText,
  StyledDeleteButtonContainer,
  StyledDeleteButton,
  StyledEditButton,
  StyledAlertHeaderMulty,
} from "./AlertInvalidMissions.styled";

export interface IAlertInvalidMissionsProps {
  modalType?: number;
  defaultCampaignLimits: number;
  wrongDates?: boolean;
  handleDatesChange?: () => void;
  onDeleteInvalidMissions?: () => void;
}
export const AlertInvalidMissions = ({
  modalType = 0,
  wrongDates,
  handleDatesChange,
  onDeleteInvalidMissions,
  defaultCampaignLimits,
}: IAlertInvalidMissionsProps) => {
  return (
    <StyledAlertContainer>
      {wrongDates ? (
        <StyledAlertHeaderMulty>
          <div style={{ display: "flex" }}>
            <Icon type="Attention" size="s" style={{ marginRight: "8px" }} />
            <StyledAlertTitle>Please update dates</StyledAlertTitle>
          </div>
          {wrongDates ? <StyledEditButton onClick={handleDatesChange}>Edit dates</StyledEditButton> : null}
        </StyledAlertHeaderMulty>
      ) : (
        <StyledAlertHeader>
          {!modalType ? <Icon type="Attention" size="s" /> : null}
          <StyledAlertTitle>{modalType ? "Update launch dates" : "Please update dates"}</StyledAlertTitle>
        </StyledAlertHeader>
      )}
      <StyledAlertText>
        {modalType
          ? `The campaign launch date can not be earlier than ${defaultCampaignLimits} business days from today. Please select a new campaign launch date.`
          : `Some distribution dates you selected previously are now invalid. Distribution dates cannot start earlier than ${defaultCampaignLimits} business days from today.`}
      </StyledAlertText>

      {!wrongDates && !modalType ? (
        <StyledDeleteButtonContainer>
          <StyledDeleteButton onClick={onDeleteInvalidMissions}>Delete all expired dates</StyledDeleteButton>
        </StyledDeleteButtonContainer>
      ) : null}
    </StyledAlertContainer>
  );
};

export default AlertInvalidMissions;
