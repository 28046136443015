import cn from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import style from "./style.js";
import PreciseLocations from "./components/campaign-location/h2h-precise-locations";
import LetterboxAreas from "./components/campaign-location/d2d-areas";
import { useStore } from "../../store";

const AudienceForm = ({ classes, isSubmitted }) => {
  const {
    campaign: { isD2D },
  } = useStore();

  return (
    <Box
      className={cn(classes.audienceFormWrapper, {
        [classes.audienceFormLeftBiggerWrapper]: isD2D,
      })}
    >
      <Box className={classes.h2hCampaignLocationWrapper}>
        {isD2D ? <LetterboxAreas isSubmitted={isSubmitted} /> : <PreciseLocations isSubmitted={isSubmitted} />}
      </Box>
    </Box>
  );
};

export default withStyles(style)(AudienceForm);
