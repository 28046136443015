const style = {
  quoteWrapper: {
    background: "#F9F9FF",
    overflow: "scroll",
    height: "100vh",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  mainWrapper: {
    width: "fit-content",
    minWidth: "100%",
    height: "fit-content",
    display: "flex",
    justifyContent: "center",
  },
  pagesWrapper: {
    marginTop: "16px",
    height: "100%",
    width: "1200px",
    display: "flex",
    justifyContent: "space-between",
  },
  page: {
    width: "592px",
    minHeight: "678px",
    background: "#FFFFFF",
    padding: "16px 16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "default",
  },
  title: {
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "34px",
    display: "flex",
    alignItems: "center",
    color: "#011533",
    marginBottom: "12px",
  },
  text: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#011533",
    wordBreak: "break-word",
  },
  row: {
    display: "flex",
    marginBottom: "4px",
    "& > div": {
      flex: 1,
    },
  },
  link: {
    color: "#EE4360",
    textDecorationLine: "underline",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    cursor: "pointer",
  },
  couponCodeLink: {
    color: "#4F5B94",
    paddingRight: "20px",
  },
  couponCodeWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "12px",
  },
  line: {
    border: "1px solid #E2E2FC",
    marginTop: "24px",
    marginBottom: "24px",
    width: "100%",
  },
  underline: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    textDecorationLine: "underline",
    color: "#0B2456",
    marginLeft: "8px",
  },
  printWrap: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "48px",
    marginRight: "16px",
    "& > div": {
      display: "flex",
      cursor: "pointer",
    },
  },
  editAudience: {
    cursor: "pointer",
    color: "#EE4360",
    textDecorationLine: "underline",
    fontWeight: "700",
  },
  scroll: {
    overflowY: "scroll",
    maxHeight: "114px",
    marginRight: "-5px",
  },
  flex: {
    display: "flex",
  },
  flex_1: {
    flex: "1",
  },
  flex_1_5: {
    flex: "1.5",
  },
  lastRows: {
    flex: "3.5",
    "& span": {
      marginLeft: "241px",
    },
  },

  cityRow: {
    marginBottom: "8px",
  },
  bold: {
    fontWeight: "700",
  },
  marginBottom_4: {
    marginBottom: "4px",
  },
  quote: {
    marginLeft: "16px",
    marginBottom: "4px",
    lineHeight: "24px",
  },
  detailsProvided: {
    marginBottom: "12px",
  },
  campaignName: {
    marginRight: "40px",
  },
  center: {
    alignItems: "center",
    textAlign: "center",
  },
  table: {
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
  },
  tableRow: {
    borderBottom: "1px solid #E2E2FC",
    display: "flex",
    "& > div": {
      marginTop: "10px",
      marginBottom: "10px",
    },
  },
  taxRow: {
    borderBottom: "1px solid #E2E2FC",
    display: "flex",
    flexDirection: "column",
    "& > div": {
      marginTop: "10px",
      marginBottom: "5px",
    },
  },
  grandTotalWrapper: {
    marginTop: "24px",
    "& > div:first-child": {
      flex: "1.5",
    },
  },
  grandTotalSubContainer_1: {
    display: "flex",
    flexDirection: "column",
    flex: "2 !important",
    background: "#F9F9FF",
    padding: "12px 0 12px  15px",
  },
  grandTotalSubContainer_2: {
    display: "flex",
  },
  taxText: {
    fontSize: 12,
    color: "#011533",
    paddingTop: "4px",
  },
  salesTaxesWarn: {
    fontSize: 12,
    color: "#EE4360",
    marginLeft: "241px",
    marginBottom: "10px",
  },
  grandTotal: {
    borderRadius: "4px 0px 0px 4px",
    marginRight: "112px",
  },
  grandTotalCost: {
    width: "124px",
    textAlign: "center",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    position: "relative",
    background: "#EE4360",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "208px",
    height: "48px",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
  buttonText: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#FFFFFF",
  },
  disabled: {
    background: "#C2C8D1",
    pointerEvents: "none",
    "&:hover": {
      transform: "none",
    },
  },
  progress: {
    position: "absolute",
    top: "14px",
    left: "30px",
    color: "#FFFFFF",
  },
  marginL4: {
    marginLeft: "4px",
  },
};

export { style };
