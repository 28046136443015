export const styles = {
  locationCardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "0px 2px 14px rgba(39, 55, 84, 0.08)",
    width: "590px",
    margin: "15px 0",
    padding: "15px 15px 10px",
  },
  clickable: {
    cursor: "pointer",
  },
  locationTitle: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  locationIcon: {
    marginRight: "5px",
    height: "17px",
  },
  clickButtonHint: {
    color: "#707087",
    fontSize: "14px",
  },
  locationOptionsBtn: {
    fill: "#C4C4C4",
    cursor: "pointer",
    "& img": {
      height: "20px",
    },
  },
  addMissionsButton: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #E2E2FC",
    borderRadius: "4px",
    padding: "4px 8px",
  },
  addMissionsButtonText: {
    fontWeight: "bold",
    textTransform: "none",
  },
  missionsListContainer: {
    marginLeft: "21px",
    marginTop: "4px",
  },
  missionContainer: {
    display: "grid",
    marginTop: "4px",
    gridTemplateColumns: "3fr 4fr 2fr 3fr 1fr",
  },
  missionItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    fontSize: "14px",
    color: "#011533",
  },
  missionTitle: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#4F5B94",
  },
  missionsCount: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: 400,
    color: "#4F5B94",
    marginLeft: "26px",
  },
  nonMissions: {
    color: "rgb(238, 67, 96)",
  },
  totalMissions: {
    marginLeft: "8px",
  },
  timeSelectItem: {
    "& > div > div > div": {
      minWidth: "120px",
      fontSize: "14px",
    },
  },
  lastItem: {
    justifyContent: "center",
  },
  bottomContainer: {
    marginLeft: "7px",
    marginTop: "12px",
    display: "flex",
    alignItems: "center",
  },
  dateContainer: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
};
