import { memo, useState } from "react";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { Autocomplete } from "components/form";
import { If, IfElse } from "components/logic";

import CursorIcon from "static/media/cursor-icon.js";

import { AlertInvalidMissions } from "./components/AlertInvalidMissions";
import { DeleteButton } from "./components/DeleteButton";
import style from "./style";

import { deleteInvalidMissionsInPreciseLocation, fetchCityDistributionZones } from "../utils";

import { PreciseLocation } from "./components/PreciseLocation";
import { getCampaignCost, getCityTimezone } from "../../../../../graphQL";
import { getCorrectSettingsObject } from "../../../../../shared/campaign";
import { useStore } from "../../../../../store";
import { RESTRICTED_H2H_CREATE_LOCATION_CITIES } from "../../../../../constants";

const PreciseLocations = memo(({ classes, isSubmitted }) => {
  const {
    costsCalculationData,
    campaign: { isD2D, id: campaignId },
    country,
    missions,
    errors: { existInvalidMissionsInH2H },
    city,
    map: { loading },
    timezone,
    updateCostsCalculationData,
    setCity,
    updateCity,
    updateMap,
    setAddresses,
    setTimezone,
  } = useStore();

  const { countryDefaultSettings, citiesSettings, citiesList } = country;

  const defaultCampaignLimits = countryDefaultSettings.defaultCampaignLimits;

  const [anchorEl, setAnchorEl] = useState(null);
  const [showCitySelect, setShowCitySelect] = useState(false);

  const isRestrictedCity = RESTRICTED_H2H_CREATE_LOCATION_CITIES.includes(city?.id ? Number(city?.id) : undefined);

  const handleCityChange = async (city) => {
    setShowCitySelect(false);
    updateMap({ loading: true });
    const cityDistributionZones = await fetchCityDistributionZones(city.id);

    const newCity = {
      ...city,
      isAddressesPopupOpened: true,
      distributionZones: cityDistributionZones[isD2D ? "d2d" : "h2h"],
    };

    const detailedCost = await getCampaignCost({
      id: campaignId,
      cityId: city.id,
    });

    const citySetting = citiesSettings.find((citySetting) => citySetting.id == city.id);

    const settings = getCorrectSettingsObject({ countrySettings: countryDefaultSettings, citySetting, isD2D });

    const cityTimezone = await getCityTimezone(city.id);
    if (cityTimezone) {
      setTimezone(cityTimezone);
    }

    updateCostsCalculationData({
      settings,
      detailedCost,
    });
    updateMap({ center: { lat: newCity.lat, lng: newCity.lng }, zoom: 12, loading: false });

    setCity(newCity);
  };

  const handleDeleteInvalidMissions = () => {
    deleteInvalidMissionsInPreciseLocation({
      city,
      setAddresses,
      setAnchorEl,
      timezone,
      defaultCampaignLimits,
    });
  };

  return (
    <Grid item xs={12} className={classes.h2hCampaignLocationWrapper}>
      {existInvalidMissionsInH2H && (
        <AlertInvalidMissions
          defaultCampaignLimits={defaultCampaignLimits}
          onDeleteInvalidMissions={handleDeleteInvalidMissions}
        />
      )}

      <Box display="flex" alignItems="flex-start">
        <Typography className={classes.city} cypress_id={"cityNameTitle"}>
          City{city ? ":" : ""}
        </Typography>
        <If condition={showCitySelect || !city}>
          <Box
            className={classes.citiesAutocomplete}
            style={{ maxWidth: "450px", marginTop: !city === 1 ? "8px" : 0 }}
            cypress_id={"selectCityDropdown"}
          >
            <Autocomplete
              name="cities"
              label="Select a city"
              value={null}
              classes={{ option: classes.optionSelect }}
              getOptionLabel={(item) => item.nameWithState}
              getOptionSelected={(option, value) => option.nameWithState === value.nameWithState}
              options={citiesList}
              onChange={(city) => {
                handleCityChange(city);
              }}
              hideLabel
              disabled={isSubmitted || loading}
            />
          </Box>
        </If>

        {city ? (
          <If condition={city.id}>
            <Box display="flex" alignItems="center" style={{ marginLeft: "10px" }}>
              <IfElse condition={city.showDeleteCityPopup}>
                <Typography component="div" className={classes.selectedCityInputDelete}>
                  <Typography component="span">{`Delete ${city.nameWithState}?`}</Typography>
                  <Box display="flex">
                    <div
                      className={classes.deleteAddressConfirmBtn}
                      onClick={() => {
                        setCity(null);
                      }}
                    >
                      {"Yes"}
                    </div>
                    <div
                      className={classes.deleteAddressDeclineBtn}
                      onClick={() => {
                        updateCity({ showDeleteCityPopup: false, isCityOptionsOpened: false });
                      }}
                    >
                      {"No"}
                    </div>
                  </Box>
                </Typography>
                <Box>
                  <Box display="flex" alignItems="center">
                    <Typography
                      component="div"
                      className={classNames(
                        classes.selectedCityInput,
                        !city.isVisible || isSubmitted ? classes.disabledCity : {}
                      )}
                    >
                      {city.nameWithState}
                    </Typography>
                    {!isSubmitted && !loading && (
                      <DeleteButton
                        className={classes.deleteCityButton}
                        onClick={() =>
                          updateCity({
                            showDeleteCityPopup: true,
                            isCityOptionsOpened: false,
                          })
                        }
                      />
                    )}
                  </Box>
                </Box>
              </IfElse>
            </Box>
          </If>
        ) : null}
      </Box>

      {missions.length > 0 ? (
        <Box className={classes.distributorsAndFlyers}>
          <Typography className={classes.totals}>
            <span>Distributors:</span>
            <span className={classes.totalValue}>{missions.length}</span>
          </Typography>
          <Typography className={classes.totals}>
            <span>Flyers:</span>
            <span className={classes.totalValue}>{costsCalculationData?.detailedCost?.printing?.quantity || 0}</span>
          </Typography>
        </Box>
      ) : (
        <Box />
      )}

      {city ? (
        <Grid xs={12} container item>
          <Box>
            <If condition={city.id}>
              <Box>
                {city && city.isAddressesPopupOpened && city.addresses ? (
                  <Box className={classes.addressesWrapper}>
                    {city.addresses.map((address) => (
                      <PreciseLocation
                        key={address.value}
                        location={address}
                        city={city}
                        setAnchorEl={setAnchorEl}
                        anchorEl={anchorEl}
                        isSubmitted={isSubmitted}
                      />
                    ))}
                  </Box>
                ) : null}
                <Box className={classes.totalsWrapper}>
                  {city.addresses.length < 3 ? (
                    <Box className={classes.selectLocationHint}>
                      <CursorIcon fill="#707087" />
                      <Typography className={classes.totals}>
                        {isRestrictedCity
                          ? "You must select locations from the recommended options on the map."
                          : "Choose locations from the recommended options or create new ones on the map."}
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </If>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
});

export default withStyles(style)(PreciseLocations);
